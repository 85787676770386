<template>
  <div class="container">
    <PublicHeader />
    <div class="nav">
      <div
        class="item"
        v-for="item in navList"
        :key="item"
        @click="handleClick(item)"
        :class="{ active: active === item }"
      >
        {{ $t('trade')[item] }}
      </div>
    </div>
    <div class="content">
      <!-- <div class="head">
        <span>{{ $t('trade').name }}/{{ $t('trade').leixing }}</span>
        <span>{{ $t('trade').cb }}/{{ $t('trade').xj }}</span>
        <span>{{ $t('trade').cc }}</span>
        <span>{{ $t('trade').yk }}</span>
        <span>{{ $t('trade').caozuo }}</span>
      </div> -->
      <NoData v-if="list.length === 0" />
      <van-list
        class="list-box"
        ref="list"
        v-else
        v-model="loading"
        :finished="finished"
        loading-text=""
        finished-text=""
        @load="getList"
      >
        <div class="info" v-for="(item, index) in list" :key="index">
          <div class="line">
            <div>
              <p>{{ $t('home').hy }}</p>
              <p>{{ item.ProductName }}</p>
            </div>
            <div>
              <p>{{ $t('trade').leixing }}</p>
              <p>{{ $t('trade')[item.BuyDirection] }}</p>
            </div>
            <div>
              <p>{{ $t('trade').shijian }}</p>
              <p>{{ item.UpdateTime | getLocalTime }}</p>
            </div>
            <div>
              <p>{{ $t('trade').jiaoyie }}</p>
              <p>{{ item.TransactionAmount }}</p>
            </div>
            <div>
              <p>{{ $t('trade').bzj }}</p>
              <p>{{ item.BondAmount }}</p>
            </div>
            <div>
              <p>{{ $t('trade').gg }}</p>
              <p>{{ item.Multiple }}</p>
            </div>
            <div>
              <p>{{ $t('trade').cb }}</p>
              <p>{{ item.StartPrice }}</p>
            </div>
            <div>
              <p>{{ $t('trade').xj1 }}</p>
              <p>{{ item.Price }}</p>
            </div>

            <div>
              <p>{{ $t('trade').jyss }}</p>
              <p>{{ item.Num }}</p>
            </div>
            <div>
              <p>{{ $t('trade').yk }}</p>
              <p>{{ item.ProfitAmount }}</p>
            </div>
            <div>
              <p>{{ $t('trade').zsj }}</p>
              <p>{{ item.StopLossPrice }}</p>
            </div>
            <div>
              <p>{{ $t('trade').zyj }}</p>
              <p>{{ item.StopSurplusPrice }}</p>
            </div>

            <div v-if="active === 'Create' || active === 'Wait'">
              <p>{{ $t('trade').caozuo }}</p>
              <p>
                <span
                  class="btn"
                  v-if="active === 'Wait'"
                  @click="handleClose(item.OrderNo)"
                  >{{ $t('trade').pc }}</span
                >
                <span class="btn" v-else @click="handleCancle(item.OrderNo)">{{
                  $t('public').cancel
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/noData'
import productApi from '@/api/product'
import PublicHeader from '@/components/publicHeaderNew'
export default {
  components: {
    NoData,
    PublicHeader
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      active: 'Create',
      navList: ['Create', 'Wait', 'Complete', 'Cancel'],
      form: {
        page: 1,
        size: 10,
        orderStatus: 'Create',
        buyDirection: null
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async handleClick(key) {
      this.active = key
      this.form.orderStatus = key
      this.reset()
    },
    reset() {
      this.form.page = 1
      this.finished = false
      this.list = []
      this.getList()
    },
    async handleCancle(OrderNo) {
      await productApi.cancelContractOrder(OrderNo)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.reset()
      }, 500)
    },
    async handleClose(OrderNo) {
      await productApi.cancelContractOrderClose(OrderNo)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.reset()
      }, 500)
    },
    async getList() {
      let form = { ...this.form }
      const res = await productApi.ContractOrderAllRecord(form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  color: #f2f5ff;
  padding-top: 44px;
  .nav {
    display: flex;
    height: 30px;
    background: #17181e;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      color: #e143ac;
    }
  }
  .content {
    .list-box {
      height: calc(100vh - 72px);
      overflow: scroll;
    }
    .info {
      background: #17181e;
      margin-top: 10px;
      padding: 10px 0;
      .line {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }
      div {
        width: 25vw;
        margin-bottom: 10px;
        text-align: center;
        line-height: 1.5;
      }
      .btn {
        border: 1px solid #d74e5a;
        padding: 1px 3px;
        display: inline-block;
        color: #d74e5a;
      }
    }
    .detail {
      display: flex;
      padding: 10px 15px;
      font-size: 12px;
      line-height: 1.5;
      justify-content: space-between;
      align-items: center;
      span {
        color: #02c289;
      }
      border: 1px solid #1e2a3d;
    }
    .btn1 {
      background: #e143ac;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 34px;
      border-radius: 6px;
    }
  }
  .head {
    display: flex;
    span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }
  }
}
</style>
